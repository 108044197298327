<template>
  <div class="wrapper">
    <div class="content">
      <div class="main">
        <slot />
      </div>
      <div
        class="side"
        :style="{ marginLeft: gutter + 'px', width: sideWidth + 'px' }"
      >
        <slot name="side" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gutter: {
      type: Number,
      default: 15,
    },

    sideWidth: {
      type: Number,
      default: 300,
    },
  },
  name: "container",
};
</script>

<style lang="scss" scoped>
.wrapper {
  .content {
    width: 1200px;
    margin: 0 auto;
    // overflow: hidden;
    display: flex;

    .side {
      flex: 0 0 auto;
      // width: 300px;
    }

    .main {
      flex: 1 1 auto;
    }
  }
}
</style>